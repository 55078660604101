import logo from 'assets/images/bkz-zorg.png';
import logoPayoff from 'assets/images/bkz-zorg-payoff.png';
import favicon from 'assets/images/bkz_favicon.png';
import favicon16 from 'assets/images/bkz_favicon-16x16.png';
import defaultConfigs from './default';

const AWS = {
  aws_project_region: 'eu-west-1',
  aws_appsync_graphqlEndpoint:
    'https://capbgxryqrgz7mk2icimnhgx4i.appsync-api.eu-west-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-west-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cloud_logic_custom: [
    {
      name: 'userapi',
      endpoint: 'https://06zqfl27bf.execute-api.eu-west-1.amazonaws.com/bkz',
      region: 'eu-west-1',
    },
  ],
  aws_cognito_identity_pool_id:
    'eu-west-1:c0fe57d0-9ab0-4874-bbf7-39adae09ab53',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_Q6HyUrtQQ',
  aws_user_pools_web_client_id: 'be2jt8nt6l4k30t9cib55s3pn',

  oauth: {
    domain: 'zorgbkz.auth.eu-west-1.amazoncognito.com',
    scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: 'https://www.zorgbkz.clinicards.app/',
    redirectSignOut: 'https://zorgbkz.clinicards.app/s',
    responseType: 'code',
  },
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OPTIONAL',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: '8',
    passwordPolicyCharacters: ['REQUIRES_NUMBERS'],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_user_files_s3_bucket: 'clinicardstorage123659-bkz',
  aws_user_files_s3_bucket_region: 'eu-west-1',
  aws_public_images_bucket: 'publicarticleimages123659-bkz',
  aws_public_images_bucket_region: 'eu-west-1',
};

const defaultCarepathCards = [];

export default {
  ...defaultConfigs,
  APP_TITLE: 'Zorg@BKZ',
  CONTENT_LANGUAGE: 'nl',
  CONTENT_LANGUAGES: ['nl', 'en'],
  ENABLE_ARTICLES: true,
  ENABLE_CAREPATH: true,
  ENABLE_CARDS: true,
  ENABLE_MEDICAL_PROFILE: false,
  ENABLE_MANAGEMENT: true,
  ENABLE_CLINICARDS: true,
  ENABLE_ORGANISATION_LEVEL: true,
  ENABLE_ORGANISATION_DETAILS: true,
  ENABLE_INSIGHTS: false,
  ENABLE_ORGANISATION_MULTIPLE: true,
  ENABLE_PERMISSIONS: true,
  ENABLE_CHILD_PROFILE: true,
  ENABLE_STACKED_CAREPATH: true,
  ENABLE_HIDE_PATIENT_BIRTHDATE: true,
  REMOVE_GENDER_FROM_ONBOARDING: true,
  ENABLE_USER_REINVITATION: true,
  ENABLE_PERSONAL_MESSAGES: true,
  ENABLE_PATIENT_TRANSFER: true,
  AWS,
  logo,
  logoPayoff,
  defaultCarepathCards,
  ENABLE_LIST_CHILD_NAME: true,
  favicon,
  favicon16,
  DEFAULTCARDS: [
    '62369046-980d-4ea6-9e78-1b1b69068e08',
    'de673886-5669-4e0d-b142-6fda146d2334',
    '82369046-980d-4ea6-9e78-1b1b69068e08',
    '9a850f84-eae3-4815-b7a2-d9a54a0c53c6',
    'caa35515-97a1-4139-abe4-b4ca8190933f',
  ],
  ENABLE_VPK: true,
  ENABLE_USAGE_ACTIVITY: true,
  ENABLE_BIRTH_INFO: false,
  VISUALISE_INACTIVE_USERS: true,
  SSO_PROVIDER: 'ZorgBKZProvider',
  ENABLE_OPENID_SSO: false,
  ENABLE_AZURE_SSO: true,
  ENABLE_PATIENT_MFA: true,
};
