export const GRAPHQL_LIMIT = 9999;

export const CLINICARD_NAMES = {
  LIFE_CONTROL: 'Mate van zelfregie',
  STEPS: 'steps',
  PROSTHESIS: 'prosthesis_questionnaire',
  VAS: 'vas_painscore',
  VAS_CHESTPAIN: 'vas_chestpain',
  VAS_SHORTBREATH: 'vas_shortbreath',
  MOOD: 'mood',
  PHOTO: 'photo',
  NOTE: 'notes',
  BMI: 'bmi',
  EXERCISE: 'exercises',
  CSI: 'caregiver_strain_index',
  PREPARE_VISIT: 'prepare_visit',
  GOALS: 'goals',
  SATURATION: 'saturation',
  TEMPERATURE: 'temperature',
  HEARTRATE: 'heartrate',
  BLOOD_PRESSURE: 'blood_pressure',
  BLOOD_PRESSURE_UPLOW: 'blood_pressure_uplow',
  BREASTFEEDING: 'breastfeeding',
  RISK_SCREENING_GENERAL: 'risk_screening_questionnaire_general',
  RISK_SCREENING_LANGUAGE_COMMUNICATION:
    'risk_screening_questionnaire_language_communication',
  RISK_SCREENING_WORK_INCOME: 'risk_screening_questionnaire_work_income',
  RISK_SCREENING_SOCIAL_ENVIRONMENT:
    'risk_screening_questionnaire_social_environment',
  RISK_SCREENING_SUBSTANCE: 'risk_screening_questionnaire_substance',
  RISK_SCREENING_SELF_RELIANCE: 'risk_screening_questionnaire_self_reliance',
  RISK_SCREENING_MIND2CARE: 'risk_screening_questionnaire_mind2care',
  RISK_SCREENING_PSYCHE: 'risk_screening_questionnaire_psyche',
  RISK_SCREENING_DELIVERY: 'risk_screening_questionnaire_delivery',
  MATERNAL_EMPOWERMENT: 'maternal_empowerment_questionnaire',
  QUALITY_OF_CARE: 'quality_of_care_questionnaire',
  QUALITY_OF_LIFE: 'quality_of_life_questionnaire',
  BREAST_FEEDING: 'breastfeeding_questionnaire',
  LIFE_STYLE: 'lifestyle_questionnaire',
  DEPRESSION: 'depression_questionnaire',
  EATING_QUESTIONNAIRE: 'eating_questionnaire',
  EXERCISES_QUESTIONNAIRE: 'exercises_questionnaire',
  SATISFIED_QUESTIONNAIRE: 'information_questionnaire',
  BARIATRIC_QUESTIONNAIRE: 'treatment_questionnaire',
  MHQ_QUESTIONNAIRE: 'mhq_questionnaire',
  PRWHE_QUESTIONNAIRE: 'prwhe_questionnaire',
  RANGE_OF_MOTION: 'rangeofmotion',
  COUGHING: 'coughing',
  COMMON_COLD_QUESTIONNAIRE: 'common_cold_questionnaire',
  VAS_FATIGUE: 'vas_fatigue',
  TONGUE_GUMS_QUESTIONNAIRE: 'tongue_gums_questionnaire',
  PET_BEHAVIOUR_HC_NAME: 'pet_behaviour_hc_name',
  PET_GEN_HEALTH: 'pet_gen_health',
  PET_DRINK: 'pet_drink',
  PET_EATING: 'pet_eating',
  PET_ACTIVITY: 'pet_activity',
  PET_BREATHING_FREQUENCY: 'pet_breathing_frequency',
  PET_MEDICATION: 'pet_medication',
  WEIGHT: 'weight_hc_name',
  MOTHER_MILK: 'mother_milk_hc_name',
  BABY_NUTRITION: 'baby_nutrition',
  MEMORIES: 'remember_hc_name',
  STRESS: 'experienced_stress_hc_name',
  POUCH: 'buidelen',
  WEIGHT_INFANT: 'weight_infant_hc_name',
  PUMPING: 'pump_hc_name',
  STRESS_QUESTIONNAIRE: 'stress_questionnaire_hc_name',
  NUMBER_OF_PATIENTS: 'number_of_patients',
  AVERAGE_SCORE_WITH_STANDARD_DEVIATION:
    'average_score_with_standard_deviation',
  PET_WEIGHT: 'pet_weight',
  DIAPER_CARD: 'hc_diaper_name',
  BILIRUBIN_CARD: 'bilirubin_hc_name',
  NEW_BREASTFEEDING: 'breastfeeding_hc_name',
  MILESTONE_CARD: 'milestones_hc_name',
};

export const SUPPORTED_DATE_FORMATS = [
  'D-M-YY',
  'D-M-YYYY',
  'D-MM-YY',
  'D-MM-YYYY',
  'DD-M-YY',
  'DD-M-YYYY',
  'DD-MM-YY',
  'DD-MM-YYYY',
];

export const CLINICARD_BOUNDARIES = {
  [CLINICARD_NAMES.TEMPERATURE]: {
    MIN: 33,
    MAX: 44,
  },
  [CLINICARD_NAMES.BMI]: {
    MIN: 0,
    MAX: 255,
  },
  [CLINICARD_NAMES.HEARTRATE]: {
    MIN: 10,
    MAX: 255,
  },
  [CLINICARD_NAMES.BLOOD_PRESSURE]: {
    MIN: 50,
    MAX: 180,
  },
  [CLINICARD_NAMES.PET_BREATHING_FREQUENCY]: {
    MIN: 100,
    MAX: 180,
  },
  [CLINICARD_NAMES.SATURATION]: {
    MIN: 60,
    MAX: 100,
  },
};
